<template>
  <div class="p-1">
    <div v-if="showError" class="w-100 text-center pt-4">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>

    <template v-else>
      <b-modal
        id="modal-login"
        hide-footer
        hide-header
        centered
        no-close-on-backdrop
        title=""
        size="sm"
      >
        <b-row align-h="center">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-row>
      </b-modal>

      <b-row v-if="schLoading" align-h="center" class="mb-1">
        <b-col class="text-center" lg="7" md="9">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-col>
      </b-row>
      <template v-else>
        <b-row align-h="center" class="mb-1">
          <b-col class="text-center" lg="7" md="9">
            <b-img
              v-if="school.logo == null || school.logo == ''"
              src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
              style="object-fit: contain"
              fluid
              height="100px"
              width="100px"
            />
            <b-img
              v-else
              :src="school.logo"
              style="object-fit: contain"
              fluid
              height="100px"
              width="100px"
            />
            <div class="mt-1">
              <h3>{{ school.name }}</h3>
              <h5>
                <feather-icon icon="MapPinIcon" class="mr-50" />
                {{ school.address }}
              </h5>
              <h5>
                <feather-icon icon="PhoneIcon" class="mr-50" />
                {{ school.phone || school.mobile }}
              </h5>
            </div>
          </b-col>
          <b-col
            v-if="!itemLoading"
            class="d-flex justify-content-center mt-1"
            lg="7"
            md="9"
          >
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="stdObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ stdObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ getClass(`${stdObj.class_current}`) }}
                </b-badge>
                <b-badge variant="light-primary" class="ml-50">
                  GR - {{ stdObj.grNo }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>

        <div>
          <b-row align-h="center" v-if="dataLoading || itemLoading">
            <b-spinner
              class="m-5"
              style="width: 4rem; height: 4rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </b-row>
          <b-row
            align-h="center"
            v-else-if="items.length == 0"
            style="height: 20vh"
          >
            <h1 class="mt-1 mb-1" style="align-self: center">
              No records found
            </h1>
          </b-row>
          <b-row class="mt-2" align-h="start" v-else>
            <b-col
              v-for="(c, index) in items"
              :key="c"
              class=""
              xl="3"
              lg="4"
              md="4"
              sm="6"
              cols="12"
            >
              <b-card
                body-class="p-1 position-relative"
                :img-src="c.cover"
                img-top
                img-height="200"
                :border-variant="color[index % color.length]"
              >
                <!-- :bg-variant="
                  clubVoted && clubVoted.id == c.id
                    ? color[index % color.length]
                    : ''
                " -->
                <!-- <b-card
              :bg-variant="color[index % color.length]"
              text-variant="white"
              class="mb-1"
              style="
                min-height: 150px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              "
            >
            </b-card> -->

                <h5 v-if="clubVoted && clubVoted.id == c.id">
                  <b-badge
                    :variant="color[index % color.length]"
                    class="text-uppercase"
                    style="
                      position: absolute;
                      top: 1rem;
                      right: 1rem;
                      z-index: 999;
                    "
                  >
                    Joined
                  </b-badge>
                </h5>
                <b-card-title class="mb-0">
                  <h3>{{ c.title }}</h3>
                  <h5>{{ getClass(c.classes) }}</h5>
                  <h5>Seats: {{ c.occupied }} / {{ c.seats }}</h5>
                </b-card-title>

                <b-card-text>
                  <b-card-text>
                    {{ c.description }}
                  </b-card-text>

                  <div
                    class="d-flex justify-content-between"
                    v-if="clubVoted ? clubVoted.id != c.id : true"
                  >
                    <b-button
                      @click="joinClub(c)"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :variant="color[index % color.length]"
                      class="btn-icon text-uppercase"
                      :disabled="request"
                    >
                      Sign up
                    </b-button>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
    if (
      !this.$route.query.db ||
      !this.$route.query.cID ||
      !this.$route.query.sID
    ) {
      this.showError = true;
    } else {
      this.db = this.$route.query.db;
      this.cID = this.$route.query.cID;
      this.encSID = this.$route.query.sID;
      this.LoadSchool();
    }
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.teacher.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.subject.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  data() {
    return {
      color: ["primary", "success", "warning", "info", "danger", "secondary"],
      rights: {},
      config: {
        enableTime: true,
        altFormat: "j M, Y h:i K",
        altInput: true,
        dateFormat: "Y-m-d\\TH:i:S",
      },
      searchQuery: "",
      dataLoading: false,
      itemLoading: false,
      items: [
        // {
        //   subject: "English",
        //   date: "2024-04-06T10:00:00",
        //   description: "Group project: Social life in the modern age",
        //   teacher: "Muhammad Ali",
        //   pic: "https://cdn.cloudious.net/file-1678094351350-637549624.png",
        // },
        // {
        //   subject: "Physics",
        //   date: "2024-04-07T11:30:00",
        //   description: "Chp#1 - Introduction to Physics",
        //   teacher: "Uzair Ahmed",
        //   pic: "https://cdn.cloudious.net/file-1678518247915-575510498.png",
        // },
        // {
        //   subject: "Mathematics",
        //   date: "2024-04-07T12:30:00",
        //   description: "Calculus",
        //   teacher: "Muhammad Athar",
        //   pic: "https://cdn.cloudious.net/file-1678464900590-606838574.jpg",
        // },
      ],
      myObj: {
        id: 0,
        cID: 0,
        secID: 0,
        subjectID: 0,
        tID: 0,
        dateTimeOfClass: null,
        link: "",
        campusID: this.$store.state.userData.cId,
      },
      currentItem: {},
      showLink: false,
      linkLoading: false,
      request: false,
      classes: [],
      classesOptions: [],
      currentSections: [],
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
      showError: false,
      db: "",
      cID: "",
      encSID: "",
      stdObj: {},
      clubVoted: {},
      school: {},
      schLoading: false,
      loggedIn: this.$store.state.stdLoggedIn,
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      otpLoading: false,
      otpContact: "",
      otpError: false,
      showOtp: false,
      code: "",
      enteredCode: [],
      verifyToggle: false,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
    }),

    openWebinar(item) {
      window.open(item.link, "_blank");
    },

    getClass(cID) {
      let ids = cID
        .split(",")
        .filter((el) => el)
        .map((el) => parseInt(el));
      let names = [];
      ids.forEach((el) => {
        let obj = this.classesOptions.find((elem) => elem.id == el);
        if (obj) names.push(obj.name);
      });
      return names.join(", ");
    },

    async joinClub(item) {
      // console.log(item);
      if (this.clubVoted) {
        Swal.fire({
          title: "Warning!",
          text: "You have already joined a club.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        });
      } else {
        let ids = item.classes
          .split(",")
          .filter((el) => el)
          .map((el) => parseInt(el));

        if (ids.includes(this.stdObj.class_current)) {
          let result = await Swal.fire({
            title: "Are you sure?",
            text: "Do You want to sign up for " + item.title + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes",
          });
          if (result.isConfirmed) {
            this.request = true;
            this.$bvModal.show("modal-login");

            let obj = {
              id: 0,
              clubID: item.id,
              sID: this.stdObj.id,
              date: new Date().toJSON().split("T")[0],
              status: "active",
              campusID: parseInt(this.cID),
            };
            // console.log(obj);
            var status = await this.post({
              url:
                this.$store.state.domain +
                "Clubs/ClubVote?db=" +
                this.db +
                "&cID=" +
                this.cID,
              body: obj,
              message: "Club Joined successfully.",
              context: this,
              token: this.token,
              showError: true,
              importing: true,
            });
            this.request = false;
            this.$bvModal.hide("modal-login");
            // console.log(status);
            if (status) {
              this.LoadItems();
              Swal.fire({
                title: "Success",
                text: "Thank you for Signing up.",
                icon: "success",
              });
            }
          }
        } else {
          Swal.fire({
            title: "Warning!",
            text: "This Club is not designated for you.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Okay",
          });
        }
      }
    },

    async LoadSchool() {
      this.schLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/GetSelected?id=" +
          this.cID +
          "&db=" +
          this.db,
        token: this.token,
      };
      let res = await this.get(obj);
      this.schLoading = false;
      if (res == "NotFound" || Array.isArray(res)) this.showError = true;
      else {
        this.LoadItems();
        this.LoadData();
        this.school = res;
      }
      // console.log("sch:", this.school);
    },
    async LoadItems() {
      this.itemLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Clubs/LoadStudent?db=" +
          this.db +
          "&cID=" +
          this.cID +
          "&id=" +
          this.encSID,
        token: this.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      // console.log(res);
      if (res.status == "success") {
        this.stdObj = res.data.student;
        this.clubVoted = res.data.clubVoted;
        this.items = res.data.clubs;
      } else this.showError = true;
      this.itemLoading = false;
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.db +
          "&cId=" +
          this.cID +
          "&dID=0",
        token: this.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);

      this.dataLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
